import { GraphQLClient } from 'graphql-request'

export const getClient = (token: Token) =>
  new GraphQLClient(process.env.GRAPHQL_API_URL, {
    headers: {
      Authorization: token ? `Bearer ${token.__raw}` : null,
    },
  })

type Token = {
  __raw: string
}
