import { useAuth0 } from '@auth0/auth0-react'
import { QueryKey, useQuery } from 'react-query'
import { getClient } from 'utils/getClient'

export const useGqlQuery = (key: QueryKey, query: string, ...options) => {
  const { getIdTokenClaims } = useAuth0()

  return useQuery(
    key,
    async (_, variables) => {
      const token = await getIdTokenClaims()
      const client = getClient(token)
      return client.request(query, variables)
    },
    ...options
  )
}
